<template>
  <div class="Part">
    <el-row style="padding: 5px 5px 5px 0;" ref="spuRow">
      <el-form>
        <el-col :span="4">
          <el-form-item label="姓名:" label-width="65px">
            <el-input v-model="name" @change="listData" size="mini" clearable></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="设备名称:" label-width="75px">
            <el-input v-model="deviceName" @change="listData" size="mini" clearable></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="9">
          <el-form-item label="创建时间:" label-width="75px">
            <el-date-picker
              v-model="creationTime"
              @change="timeChange"
              unlink-panels
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd HH:ss:mm"
              value-format="yyyy-MM-dd HH:ss:mm"
              style="width: 100%"
              size="mini"></el-date-picker>
          </el-form-item>
        </el-col>
      </el-form>
    </el-row>
    <!-- 内容表格 -->
    <div>
      <el-table
        :data="dataList"
        highlight-current-row
        @cell-click="currentChangeData"
        @row-dblclick="dbClickData"
        :height='tableHeight'
        v-loading="loading"
        style="width:100%;">
        <el-table-column prop="ifpName" label="姓名" align="center" min-width="50"></el-table-column>
        <el-table-column prop="ifpPersonType" label="类型" align="center" min-width="50"></el-table-column>
        <el-table-column label="照片" align="center" min-width="100">
          <template slot-scope="scope">
            <el-image :src="scope.row.ifpGuidPhoto" style="width: 60px;height: 50px;">
              <div slot="error" class="image-slot">
                <img src="../../assets/images/userImage.png" style="width: 60px;height: 50px;">
              </div>
            </el-image>
            <!-- <img :src="scope.row.ifpGuidPhoto" style="width: 60px;height: 50px;"/> -->
          </template>
        </el-table-column>
        <el-table-column prop="personnelNumber" show-overflow-tooltip label="人员编号" align="center" min-width="100"></el-table-column>
        <el-table-column prop="devNickname" label="设备名称" align="center" min-width="100"></el-table-column>
        <el-table-column prop="devSn" label="设备序列号" align="center" min-width="100"></el-table-column>
        <el-table-column prop="ifpdTime" label="操作时间" align="center" min-width="100"></el-table-column>
        <el-table-column prop="ifpdStatus" label="状态" align="center" min-width="100"></el-table-column>
        <el-table-column label="操作" align="center" min-width="100">
          <template slot-scope="scope">
            <span class="operation-1" @click.stop="lookDetails(scope.row)">查看</span>
            <span class="operation-2" @click.stop="editor(scope.row)" v-if="scope.row.ifpdStatus == '失败'">重新授权</span>
            <span class="operation-2" @click.stop="deleteAuthorized(scope.row)" v-else>销权</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background layout="total, sizes, prev, pager, next, jumper"
        align="center"
        :page-count="pages"
        :page-size="size"
        :total="total"
        :current-page="current"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :page-sizes="[5,10,20,40]"
      ></el-pagination>
    </div>
<!--    弹出框-->
    <el-dialog title="查看" :visible.sync="detailsDialog" :close-on-click-modal="false" class="look-details" width="700px">
      <el-form>
        <el-row class="look-row">
          <el-col>
            <el-form-item label="姓名" :label-width="labelWidth">{{row.ifpName}}</el-form-item>
            <el-form-item label="人员编号" :label-width="labelWidth">{{row.personnelNumber}}</el-form-item>
            <el-form-item label="照片" :label-width="labelWidth">
              <img width="130px" :src="row.ifpGuidPhoto" @click="lookImg(row.ifpGuidPhoto)" alt="" style="cursor: pointer">
            </el-form-item>
            <hr style="border:none;background-color: #eeefef;height: 1px;">
            <el-form-item label="设备名称" :label-width="labelWidth">{{row.devNickname}}</el-form-item>
            <el-form-item label="设备序列号" :label-width="labelWidth">{{row.devSn}}</el-form-item>
            <el-form-item label="类型" :label-width="labelWidth">{{row.ifpPersonType}}</el-form-item>
            <el-form-item label="授权时间" :label-width="labelWidth">{{row.ifpdTime}}</el-form-item>
            <hr style="border:none;background-color: #eeefef;height: 1px;">
            <el-form-item label="授权状态" :label-width="labelWidth">{{row.ifpdStatus}}</el-form-item>
            <el-form-item label="授权说明" :label-width="labelWidth">{{row.ifpdErrorReason}}</el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
<!--    查看照片-->
    <el-dialog :visible.sync="dialogImg" :close-on-click-modal="false" append-to-body>
      <img width="400px" :src="dialogImageUrl" alt="" style="display:block;margin:0 auto">
    </el-dialog>
  </div>
</template>
<script>
export default {
  name:'superuser',
  data(){
    return{
      dataList: [],
      tableHeight: window.innerHeight - 150,
      row: '',
      name: '',
      deviceName: '',
      creationTime: [],
      detailsDialog: false,
      labelWidth: '150px',
      dialogImg: false,
      dialogImageUrl: '',
      loading: false,

      total: 0,         //总条数
      size: 20,        //每页的条数
      pages: 0,      //每页数据
      current: 0,    //初始页
    }
  },
  created() {
    this.listData()
  },
  updated(){
    this.tableHeight = window.innerHeight - this.$refs.spuRow.$el.offsetHeight - 130
  },
  methods: {
    listData(){
      this.$axios({
        method: 'get',
        url: `/common2/facePersonDevice`,
        params: {
          cursor: this.current,
          limit: this.size,
          personName: this.name,
          deviceName: this.deviceName,
          startTime: this.creationTime[0],
          endTime: this.creationTime[1]
        }
      }).then(res => {
        this.dataList = res.data.result.records.reverse()
        this.total = res.data.result.total;
        this.dataList.forEach(item => {
          item.ifpGuidPhoto = JSON.parse(item.ifpGuidPhoto)[0] ? JSON.parse(item.ifpGuidPhoto)[0].faceUrl : ''
        })
      }).catch(err => {
        this.isError(err,this)
      })
    },
    currentChangeData(row){//当表改变时
      this.row = row;
      console.log(this.row);
    },
    dbClickData(){

    },
    handleCurrentChange(cursor){
      this.current = cursor
      this.listData()
    },
    handleSizeChange(limit){
      this.size = limit
      this.listData()
    },
    timeChange(){
      if(this.creationTime == null){
        this.creationTime = []
      }
      this.listData()
    },
    lookDetails(row){
      this.detailsDialog = true
      this.row = row
    },
    lookImg(imgUrl){
      this.dialogImg = true
      this.dialogImageUrl = imgUrl
    },
    editor(row){//重新授权
      row.devFirstType == 24 && this.faceRecognition(row)
      row.devFirstType == 33 && this.faceRegistration(row)
    },
    faceObj(jourDeviceId,ifpTag,ifpPersonType){
      let obj = {
        deviceIds: jourDeviceId,
        personnelId: ifpTag,
        personnelType: ifpPersonType
      }
      return obj
    },
    faceRecognition(row){//人脸识别重新授权
      this.loading = true
      let faceIdentifyArr = []
      faceIdentifyArr.push(row.jourDeviceId)
      this.$axios({
        method: 'post',
        url: '/common2/facePersons',
        data: this.faceObj(faceIdentifyArr,row.ifpTag,row.ifpPersonType)
      }).then(res => {
        this.$message.success('授权成功')
        this.listData()
        this.loading = false
      }).catch(err => {
        this.loading = false
        this.isError(err,this)
      })
    },
    faceRegistration(row){//人脸抓拍重新授权
      this.loading = true
      let faceSnapyArr = []
      faceSnapyArr.push(row.jourDeviceId)
      this.$axios({
        method: 'post',
        url: '/common2/facePersons/faceRegistration',
        data: this.faceObj(faceSnapyArr,row.ifpTag,row.ifpPersonType)
      }).then(res => {
        this.$message.success('授权成功')
        this.listData()
        this.loading = false
      }).catch(err => {
        this.loading = false
        this.isError(err,this)
      })
    },
    faceIdentifyDelete(row){//人脸识别删除授权
      this.loading = true
      this.$axios({
        method: 'delete',
        url: `/common2/facePersons/${row.ifpId}/marketing`,
        params: {
          deviceIds: row.jourDeviceId,
          personnelType: '员工',
        }
      }).then(res => {
        this.$message.success('销权成功')
        this.listData()
        this.loading = false
      }).catch(err => {
        this.loading = false
        this.isError(err,this)
      })
    },
    faceSnapDelete(row){//人脸抓拍删除授权
      this.loading = true
      this.$axios({
        method: 'delete',
        url: `/common2/facePersons/${row.ifpId}/deviceFaceDelete`,
        params: {
          deviceId: row.jourDeviceId
        }
      }).then(res => {
        this.$message.success('销权成功')
        this.listData()
        this.loading = false
      }).catch(err => {
        this.loading = false
        this.isError(err,this)
      })
    },
    deleteAuthorized(row){//销权
      row.devFirstType == 24 && this.faceIdentifyDelete(row)
      row.devFirstType == 33 && this.faceSnapDelete(row)
    }
  }
}
</script>
<style lang="scss" scoped>
  .operation-1{
    color: #409EFF;
    margin-right: 5px;
    cursor: pointer;
  }
  .operation-2{
    color: #F56C6C;
    cursor: pointer;
  }
  .look-details{
    /deep/ .el-dialog__header{
      border-bottom: 1px solid #eeefef;
    }
  }
  .look-row{
    /deep/ .el-form-item__label{
      text-align: left;
      color: #909399;
    }
  }
</style>
